import React, { Suspense, useState } from "react";
import { lazy } from "react";
import { useTranslation } from "react-i18next";
import { PageSkeleton } from "src/components/common/Skeletons";
import { activeTheme, getDomainMeta  } from "src/themes";

const Template = lazy(() =>
  import(`src/templates/${activeTheme}/views/SupportForm.jsx`)
);

const SupportForm = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    category: "General Support",
    message: "",
  });
  const [saving, setSaving] = useState(false);
  const domainMeta = getDomainMeta();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSaving(true);

    const emailBody = `
      ${formData.name}
      ${formData.email}
      ${formData.category}
      ${formData.message}
    `;

    window.location.href = `mailto:${domainMeta.mail}?subject=${
      formData.category
    } Support Request&body=${encodeURIComponent(emailBody)}`;

    setSaving(false);
  };

  return (
    <Suspense fallback={<PageSkeleton />}>
      <Template
        t={t}
        formData={formData}
        saving={saving}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
      />
    </Suspense>
  );
};

export default SupportForm;
